export const locale = {
  lang: 'en',
  data: {
    MENU: {
      LISTING: 'Listing',
      SAMPLE: 'Sample',
      HELP: 'Help',
      PAGES: {
        HELP: {
          TITLE: 'Help',
          MALE: 'Gentlemen',
          FEMALE: 'Ladies'
        },
        WHYJOIN: {
          TITLE: 'Why Join',
          MALE: 'Gentlemen',
          FEMALE: 'Ladies'
        }
      }
    }
  }
}
