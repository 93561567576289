import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'listing',
    title: 'Listing',
    translate: 'MENU.LISTING',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'sample',
    title: 'Sample Profile',
    translate: 'MENU.SAMPLE',
    type: 'item',
    icon: 'file',
    url: 'pages/profile'
  },
  {
    id: 'help',
    title: 'Help',
    translate: 'MENU.PAGES.HELP.TITLE',
    type: 'collapsible',
    icon: 'help-circle',
    children: [
      {
        id: 'help-male',
        title: 'Gentlemen',
        translate: 'MENU.PAGES.HELP.MALE',
        type: 'item',
        url: 'pages/help/male'
      },
      {
        id: 'help-female',
        title: 'Ladies',
        translate: 'MENU.PAGES.HELP.FEMALE',
        type: 'item',
        url: 'pages/help/female'
      }
    ]
  },
  {
    id: 'why-join',
    title: 'Why join',
    translate: 'MENU.PAGES.WHYJOIN.TITLE',
    type: 'collapsible',
    icon: 'thumbs-up',
    children: [
      {
        id: 'why-join-male',
        title: 'Gentlemen',
        translate: 'MENU.PAGES.WHYJOIN.MALE',
        type: 'item',
        url: 'pages/why-join/male'
      },
      {
        id: 'why-join-female',
        title: 'Ladies',
        translate: 'MENU.PAGES.WHYJOIN.FEMALE',
        type: 'item',
        url: 'pages/why-join/female'
      }
    ]
  }
]
