<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <p>
      TheOtherBoard is an adult dating community featuring Profiles, Reviews, References, Listings and Discussion Forums.
    </p>
    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body pb-1">
              <div class="card">

                <!-- Europe Collapsed start -->
                <div
                  (click)="isCollapsedEurope = !isCollapsedEurope"
                  [attr.aria-expanded]="!isCollapsedEurope"
                  aria-controls="europe-collapse"
                  class="card-header collapse-title"
                  data-toggle="collapse"
                >
                  EUROPE
                </div>
                <div [ngbCollapse]="isCollapsedEurope">
                  <div class="card">
                    <div class="card-body">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                      brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                      marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                      beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                    </div>
                  </div>
                </div>
                <!-- Europe Collapsed end -->

                <!-- Canada Collapsed start -->
                <div
                  (click)="isCollapsedCanada = !isCollapsedCanada"
                  [attr.aria-expanded]="!isCollapsedCanada"
                  aria-controls="canada-collapse"
                  class="card-header collapse-title"
                  data-toggle="collapse"
                >
                  CANADA
                </div>
                <div [ngbCollapse]="isCollapsedCanada">
                  <div class="card">
                    <div class="card-body">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                      brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                      marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                      beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                    </div>
                  </div>
                </div>
                <!-- Canada Collapsed end -->

                <!-- United States Collapsed start -->
                <div
                  (click)="isCollapsedUnitedStates = !isCollapsedUnitedStates"
                  [attr.aria-expanded]="!isCollapsedUnitedStates"
                  aria-controls="unitedStates-collapse"
                  class="card-header collapse-title"
                  data-toggle="collapse"
                >
                 UNITED STATES
                </div>
                <div [ngbCollapse]="isCollapsedUnitedStates">
                  <div class="card">
                    <div class="card-body">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                      brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                      marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                      beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                    </div>
                  </div>
                </div>
                <!-- United States Collapsed end -->

                <!-- Asia Collapsed start -->
                <div
                  (click)="isCollapsedAsia = !isCollapsedAsia"
                  [attr.aria-expanded]="!isCollapsedCanada"
                  aria-controls="asia-collapse"
                  class="card-header collapse-title"
                  data-toggle="collapse"
                >
                  ASIA, PACIFIC AND MIDDLE EAST
                </div>
                <div [ngbCollapse]="isCollapsedAsia">
                  <div class="card">
                    <div class="card-body">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                      brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                      marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                      beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                    </div>
                  </div>
                </div>
                <!-- Asia Collapsed end -->

                <!-- Latin Collapsed start -->
                <div
                  (click)="isCollapsedLatin = !isCollapsedLatin"
                  [attr.aria-expanded]="!isCollapsedLatin"
                  aria-controls="latin-collapse"
                  class="card-header collapse-title"
                  data-toggle="collapse"
                >
                  LATIN AMERICA AND CARIBBEAN
                </div>
                <div [ngbCollapse]="isCollapsedLatin">
                  <div class="card">
                    <div class="card-body">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                      brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                      marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                      beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                    </div>
                  </div>
                </div>
                <!-- Latin Collapsed end -->

                <!-- Ocenia Collapsed start -->
                <div
                  (click)="isCollapsedOcenia = !isCollapsedOcenia"
                  [attr.aria-expanded]="!isCollapsedOcenia"
                  aria-controls="ocenia-collapse"
                  class="card-header collapse-title"
                  data-toggle="collapse"
                >
                  AUSTRALIA AND OCEANIA
                </div>
                <div [ngbCollapse]="isCollapsedOcenia">
                  <div class="card">
                    <div class="card-body">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                      brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                      marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                      beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                    </div>
                  </div>
                </div>
                <!-- Ocenia Collapsed end -->

                <!-- Africa Collapsed start -->
                <div
                  (click)="isCollapsedAfrica = !isCollapsedAfrica"
                  [attr.aria-expanded]="!isCollapsedAfrica"
                  aria-controls="africa-collapse"
                  class="card-header collapse-title"
                  data-toggle="collapse"
                >
                  AFRICA
                </div>
                <div [ngbCollapse]="isCollapsedAfrica">
                  <div class="card">
                    <div class="card-body">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
                      brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
                      marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
                      beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                    </div>
                  </div>
                </div>
                <!-- Africa Collapsed end -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
